<template>
  <transition name="fade" v-on:enter="onloading">
    <div v-if="show" id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div class="box-holder animated bounceInDown">
            <span class="load-box"><span class="box-inner"></span></span>
          </div>
          <div class="text-holder text-center">
            <h3>{{ name }}</h3>
            <h6>{{ status }}</h6>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      show: false,
      name: "Bara Ramadhan Asdisusilo",
      status: "Software Engineer & Python Developer",
    };
  },
  mounted: function () {
    this.onWindowLoad(); //method1 will execute at pageload
  },
  methods: {
    onWindowLoad() {
      // console.log("window load event");
      this.show = !this.show
    },
    onloading(){
      var that = this;

      setTimeout(function() {
        that.show = false;
      }, 2000);
    }
  },
};
</script>

<style scoped>
#loading {
  /*pre-loader*/
  background-color: #06a763;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 5001;
  margin-top: 0;
  top: 0;
}
#loading #loading-center {
  /*pre-loader positioning*/
  width: 100%;
  height: 100%;
  position: relative;
}
#loading #loading-center-absolute {
  /*pre-loader positioning*/
  position: absolute;
  left: 50%;
  top: 50%;
  height: 200px;
  width: 271px;
  margin-top: -123px;
  margin-left: -136px;
}
#loading .text-holder {
  /*pre-loader text*/
  width: 100%;
  position: absolute;
  color: #fff;
  top: 62%;
}
#loading .text-holder h3 {
  /*name*/
  padding: 0;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
}
#loading .text-holder h6 {
  /*detail*/
  font-size: 16px;
  padding: 0;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
}
#loading .box-holder {
  /*pre-loader box*/
  position: absolute;
  top: 42%;
  left: 45%;
}
#loading .box-holder .load-box {
  /*outer box*/
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #fff;
  top: 50%;
  animation: loader 2s infinite ease;
  -webkit-animation: loader 2s infinite ease;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
#loading .box-holder .load-box .box-inner {
  /*inner box*/
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #1976d2;
  animation: loader-inner 2s infinite ease-in;
  -webkit-animation: loader-inner 2s infinite ease-in;
}
@-webkit-keyframes loader {
  /*loader animation*/
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader {
  /*loader animation*/
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes loader-inner {
  /*loader-inner animation*/
  0% {
    height: 0;
  }
  25% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@keyframes loader-inner {
  /*loader-inner animation*/
  0% {
    height: 0;
  }
  25% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
</style>