<template>
  <div class="home" id="home">
    <Loading />
    <div class="header-background section">
      <div id="v-card-holder">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <b-card no-body id="v-card" class="card">
                <div id="profile" class="right">
                  <img
                    alt="profile-image"
                    class="img-responsive"
                    src="img/profile1.png"
                  />
                  <div class="slant"></div>
                </div>

                <div class="card-content">
                  <div class="info-headings">
                    <h3 class="text-uppercase left">{{ name }}</h3>
                    <h6 class="text-capitalize left">
                      {{ status }}
                    </h6>
                  </div>
                  <div class="infos">
                    <ul class="profile-list">
                      <li class="clearfix">
                        <span class="title"
                          ><i class="material-icons">email</i></span
                        >
                        <span class="content">{{ email }}</span>
                      </li>
                      <li class="clearfix">
                        <span class="title"
                          ><i class="material-icons">place</i></span
                        >
                        <span class="content">{{ place }}</span>
                      </li>
                    </ul>
                  </div>

                  <div class="links">
                    <b-link
                      href="https://github.com/awkz"
                      class="social btn-floating blue-grey darken-4" 
                      ><i class="fa fa-github"></i
                    ></b-link>
                    <b-link
                      href="https://instagram.com/baraduss"
                      class="social btn-floating red accent-3"
                      ><i class="fa fa-instagram"></i
                    ></b-link>
                    <b-link
                      href="https://twitter.com/arabkebalik"
                      class="social btn-floating light-blue accent-3"
                      ><i class="fa fa-twitter"></i
                    ></b-link>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";

export default {
  name: "Home",
  components: {
    Loading,
  },
  data: function () {
    return {
      name: "Bara Ramadhan",
      status: "Software Engineer & Python Developer",
      email: "me@bara.my.id",
      place: "Pemalang, Central Java, ID",
    };
  },
};
</script>


<style scoped>
/* // Mixin itself */
ul {
  list-style-type: none;
}
#home .menu {
  /*side menu*/
  position: fixed;
  left: -225px;
  width: 225px;
  height: 100%;
  top: 0;
  z-index: 10;
  text-align: left;
  background: #06a763;
}
#home .menu a {
  /*menu links*/
  margin-left: 30px;
  margin-right: 30px;
  color: #616161;
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
#home .menu a:hover {
  /*link option hover color*/
  color: #1976d2;
}
#home .menu a:active {
  /*option active color*/
  color: #1976d2;
}
#home .menu i {
  /*menu icon*/
  padding: 7px;
}
#home .menu .menu-wrap {
  /*menu warp class*/
  position: absolute;
  top: 0;
  left: 0;
  background: #f5f5f5;
  width: 225px;
  height: 100%;
  overflow-y: auto;
  padding-top: 14px;
}
#home .menu .logo-flat {
  /*menu logo*/
  height: 200px;
  margin-top: -14px;
  background: #06a763;
}
#home .menu .logo-flat img {
  /*logo image*/
  width: 100%;
  padding: 30px 44px 44px 44px;
}
#home .menu-open {
  /*set left 0 to open menu*/
  left: 0;
}
#home .body-push {
  overflow-x: hidden;
  position: relative;
  right: 0;
}
#home .body-push-toright {
  /*push to right*/
  right: 225px;
}
#home .body-push-toleft {
  /*push to left on click*/
  right: 225px;
}
#home .menu,
#home .body-push {
  /*menu easing effect*/
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#home #menuToggle {
  /*menu toggle style*/
  position: absolute;
  top: 20px;
  right: -65px;
  z-index: 11;
  display: block;
  text-align: center;
  font-size: 14px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  border: 1px solid rgba(93, 92, 92, 0.52);
  background: rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#home #menuToggle:hover {
  /*toggle hover style*/
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#home #menuToggle .toggle-normal {
  font-size: 31px;
}
#home #menuToggle .toggle-normal i {
  font-size: 31px;
  padding: 4px !important;
  color: #1976d2 !important;
}
#home #menuToggle .toggle-normal .top-bar {
  position: absolute;
  right: 0;
  top: -6px;
  transition: 0.8s;
}
#home #menuToggle .toggle-normal .middle-bar {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  transition: 0.8s;
}
#home #menuToggle .toggle-normal .bottom-bar {
  position: absolute;
  right: 0;
  top: 6px;
  transform: rotate(0deg);
  transition: 0.8s;
}
#home #menuToggle .top-transform {
  transform: rotate(45deg) !important;
  transform-origin: 34% 56%;
}
#home #menuToggle .middle-transform {
  opacity: 0 !important;
}
#home #menuToggle .bottom-transform {
  transform: rotate(-45deg) !important;
  transform-origin: 28% 43%;
}
#home .header-background {
  /*home background*/
  position: relative;
  display: block;
  width: 100%;
  height: 370px;
  /* background: url("/img/background.png") no-repeat; */
  background-color: #3e3e3e;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
#home .header-background #v-card-holder {
  /*card holder*/
  position: absolute;
  overflow: visible;
  width: 100%;
  height: auto;
  top: 171px;
}
#home .header-background #v-card-holder #v-card {
  /*v-card with fixed 400px height*/
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  z-index: 1;
  background: #06a763;
  margin: -20px 3px 0 0;
}
#home .header-background #v-card-holder #v-card .info-headings {
  /*basic infos*/
  max-width: 514px;
}
#home .header-background #v-card-holder #v-card .info-headings h3 {
  /*name*/
  display: block;
  width: 100%;
  font-weight: 900;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
}
#home .header-background #v-card-holder #v-card .info-headings h6 {
  /*details*/
  display: block;
  width: 100%;
  font-weight: 300;
  margin-top: -10px;
  margin-bottom: 30px;
  font-size: 1.2em;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
}
#home .header-background #v-card-holder #v-card .infos {
  /*detailed infos*/
  max-width: 515px;
  margin-top: 20px;
}
#home .header-background #v-card-holder #v-card .infos .profile-list {
  /*list*/
  padding: 0px;
  word-wrap: break-word;
}
#home .header-background #v-card-holder #v-card .infos .profile-list .title {
  /*info title*/
  display: block;
  float: left;
  color: #fff;
  line-height: 26px;
}
#home .header-background #v-card-holder #v-card .infos .profile-list .title i {
  /*matching icon*/
  font-size: 23px;
}
#home
  .header-background
  #v-card-holder
  #v-card
  .infos
  .profile-list
  .title
  .fa {
  /*for font awesome*/
  /* padding-left: 2px; */
}
#home .header-background #v-card-holder #v-card .infos .profile-list .content {
  /*infos content*/
  display: block;
  margin-left: 30px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
}
#home .header-background #v-card-holder #v-card .links {
  /*social links*/
  max-width: 500px;
  margin-left: -12px;
  margin-top: 12px;
  height: 100%;
}
#home .header-background #v-card-holder #v-card .links .social {
  /*class*/
  margin-left: 12px;
  margin-bottom: 4px;
}
#home .header-background #v-card-holder #v-card .links .social i {
  /*icon*/
  font-size: 15px;
  vertical-align: middle;
  padding-bottom: 15px;
}
@media screen and (max-width: 400px) {
  #home .header-background #v-card-holder #v-card .links .social {
    /*making links responsive*/
    margin-left: 5px;
  }
}
@media screen and (max-width: 990px) {
  #home .header-background #v-card-holder #v-card .infos {
    /*max width for infos*/
    max-width: 278px;
  }
  #home .header-background #v-card-holder #v-card .links {
    /*max width for links*/
    max-width: 325px;
  }
  #home .header-background #v-card-holder #v-card .info-headings {
    /*max width for headings*/
    max-width: 270px;
  }
}
#home .header-background #v-card-holder #v-card #profile {
  /*profile area*/
  position: absolute;
  right: 0;
  z-index: 1;
}
#home .header-background #v-card-holder #v-card #profile img {
  /*user image for 400x400px image*/
  width: 400px;
  height: auto;
}
#home .header-background #v-card-holder #v-card #profile .slant {
  /*slant*/
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-style: dashed;
  border-width: 400px 0 0 100px;
  border-color: transparent transparent transparent #06a763;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #home .header-background #v-card-holder #v-card #profile .slant {
    /*slant properties for ie*/
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-style: solid;
    border-width: 400px 0 0 100px;
    border-color: transparent transparent transparent #06a763;
  }
}
@-moz-document url-prefix() {
  #home .header-background #v-card-holder #v-card #profile .slant {
    /*for firefox*/
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-style: solid;
    border-width: 400px 0 0 100px;
    border-color: transparent transparent transparent #06a763;
  }
}
@supports (-ms-accelerator: true) {
  #home .header-background #v-card-holder #v-card #profile .slant {
    /*for edge*/
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-style: solid;
    border-width: 400px 0 0 100px;
    border-color: transparent transparent transparent #06a763;
  }
}
#home .header-background #v-card-holder #v-card #profile .add-btn {
  /*plus button*/
  position: absolute;
  background-color: #1976d2;
  top: 20%;
  right: 86%;
  overflow: hidden;
}
#home .header-background #v-card-holder #v-card #profile .add-btn i {
  /*icon*/
  font-size: 35px;
  color: #fff;
}
@media screen and (max-width: 860px) {
  #home {
    /*at max width 767*/
  }
  #home .header-background #v-card-holder #v-card {
    /*v-card at max width 767*/
    position: relative;
    width: 100%;
    height: auto;
    overflow: visible;
    z-index: 1;
    top: 34px;
  }
  #home .header-background #v-card-holder #v-card #profile {
    /*profile v-card at max width 767*/
    position: absolute;
    bottom: 85%;
    left: 0;
    width: 150px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;
    background-color: transparent;
  }
  #home .header-background #v-card-holder #v-card #profile img {
    /*image at max width 767*/
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  #home .header-background #v-card-holder #v-card #profile .slant {
    /*hide slant*/
    display: none;
    opacity: 0;
    overflow: hidden;
  }
  #home .header-background #v-card-holder #v-card #profile .add-btn {
    /*hide add button*/
    display: none;
    opacity: 0;
    overflow: hidden;
  }
  #home .header-background #v-card-holder #v-card .social {
    /*links styles at max width 767*/
    top: 0;
    margin-left: 5px;
    margin-bottom: 4px;
  }
  #home .header-background #v-card-holder #v-card .infos {
    /*infos at max width 767*/
    display: table;
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
  }
  #home .header-background #v-card-holder #v-card .links {
    /*links at max width 767*/
    display: table;
    max-width: 100%;
    padding: 0;
    margin: 0 auto 9px;
  }
  #home .header-background #v-card-holder #v-card .links #first_one {
    /*first link at max width 767*/
    margin-left: 0;
  }
  #home .header-background #v-card-holder #v-card .info-headings {
    /*heading at max width 767*/
    max-width: 100%;
    text-align: center;
    margin-top: 38px;
  }
  #home .header-background #v-card-holder #v-card .info-headings h6 {
    margin-bottom: 20px;
  }
  #home .header-background #v-card-holder #v-card .card-content {
    /*content padding*/
    /* padding: 40px; */
  }
}
.card-content {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
/*
-------------------------------------------------------------------------------
4. ABOUT
-------------------------------------------------------------------------------
*/
/*about section*/
#about {
  /*profile about*/
  margin-top: 200px;
}
#about .about-btn {
  /*about buttons*/
  height: auto;
  margin-right: auto;
  margin-left: auto;
  animation-delay: 1s;
  text-align: center;
}
#about .about-btn .btn {
  /*button style*/
  margin: 7px 7px;
  background: #06a763;
  color: #ffffff;
  width: 180px;
  border-radius: 2px;
}
#about .about-btn .btn:hover {
  /*hover effect*/
  background: #046c40;
}

</style>