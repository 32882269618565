<template>
  <b-row class="page text-center">
    <b-col>
      <h1>Not Found!</h1>
      <p>
        <b-link href="/">Go home?</b-link>
      </p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.page {
}
</style>